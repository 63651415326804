<template>
  <v-dialog v-model="dialog" width="650" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small outlined v-bind="attrs" v-on="on">
        {{ studentData.student_tin }}
        <v-icon class="ml-2" size="18">mdi-account-edit</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-form v-model="formIsValid" ref="form" @submit.prevent="updateTin">
        <v-card-title class="text-h5">
          <v-row class="align-center">
            <v-col> NIF {{ studentData.student_name }} {{ studentData.student_surname }} </v-col>
            <v-col cols="auto">
              <v-btn fab small depressed @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="name"
            label="Nombre"
            outlined
            :rules="[$data.$globalRules.required]"
          />
          <v-text-field
            v-model="surname"
            label="Apellidos"
            outlined
            :rules="[$data.$globalRules.required]"
          />
          <v-text-field
            v-model="tin"
            label="NIF"
            outlined
            :rules="[$data.$globalRules.required]"
            @keyup="cleanTin"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit" :loading="updatingTin">Guardar</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { cleanTin } from "@/utils/helpers";

export default {
  props: {
    studentData: { type: Object, required: true },
  },
  data: () => ({
    dialog: null,
    tin: null,
    name: null,
    surname: null,
    formIsValid: null,
    updatingTin: null,
  }),
  methods: {
    async updateTin() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        try {
          this.updatingTin = true;
          const payload = {
            inscription_id: this.studentData.inscription_id,
            student_id: this.studentData.student_id,
            tin: this.tin,
            name: this.name,
            surname: this.surname,
          };
          await this.$store.state.currentService.updateStudentTin(payload);
          this.$emit("getSessionData");
          this.dialog = false;
        } catch (e) {
          this.$notifyError(undefined, "Error al guardar el NIF | " + e);
        } finally {
          this.updatingTin = false;
        }
      }
    },
    cleanTin() {
      this.tin = cleanTin(this.tin);
    },
  },
  watch: {
    dialog(opened) {
      if (opened) {
        this.tin = this.studentData.student_tin || "";
        this.name = this.studentData.student_name || "";
        this.surname = this.studentData.student_surname || "";
      }
    },
  },
};
</script>

<style scoped></style>
